import { FiTrendingUp, FiDollarSign, FiCompass,FiCommand, FiBox, FiCamera, FiBell, FiMonitor } from '../assets/icons/vander'
import client1 from '../assets/images/team/01.jpg'
import client2 from '../assets/images/team/02.jpg'
import client3 from '../assets/images/team/03.jpg'
import client4 from '../assets/images/team/04.jpg'
import client5 from '../assets/images/team/05.jpg'
import client6 from '../assets/images/team/06.jpg'

import blog1 from '../assets/images/blog/01.jpg'
import blog2 from '../assets/images/blog/02.jpg'
import blog3 from '../assets/images/blog/03.jpg'

import company1 from '../assets/images/team/amazon.svg'
import company2 from '../assets/images/team/google.svg'
import company3 from '../assets/images/team/lenovo.svg'
import company4 from '../assets/images/team/paypal.svg'
import company5 from '../assets/images/team/shopify.svg'
import company6 from '../assets/images/team/spotify.svg'

import services_savings from '../assets/icons/services/1.png'
import services_flexibility from '../assets/icons/services/2.png'
import services_cryptocurrency from '../assets/icons/services/3.png'
import services_privacy from '../assets/icons/services/5.png'
import services_security from '../assets/icons/services/4.png'
import services_social from '../assets/icons/services/6.png'
import services_value from '../assets/icons/services/7.png'
import services_transaction from '../assets/icons/services/8.png'

export const servicesData = [
    {
        icon:FiTrendingUp,
        image:services_savings,
        title:'Ahorro Económico',
        desc:'Optimiza recursos: Intercambia servicios y bienes sin desembolsar dinero, aprovechando al máximo tus recursos y los de tu comunidad.'
    },
    {
        icon:FiDollarSign,
        image: services_flexibility,
        title:'Flexibilidad Laboral',
        desc:'Trabaja sin límites: La libertad de trabajar cuando y donde prefieras, abriendo un mundo de posibilidades sin restricciones geográficas.'
    },
    {
        icon:FiCompass,
        image: services_cryptocurrency,
        title:'Uso de Criptomoneda',
        desc:'Innovación financiera: Realiza transacciones seguras con LTCOIN, nuestra criptomoneda, para una economía más inclusiva y moderna.'
    },
    {
        icon:FiCommand,
        image: services_privacy,
        title:'Privacidad Financiera',
        desc:'Seguridad y confidencialidad: Tus transacciones y patrimonio se mantienen privados, protegiendo tu información financiera con tecnología de punta.'
    },
    {
        icon:FiBox,
        image: services_security,
        title:'Seguridad Antifraudes',
        desc:'Confianza y protección: La estructura blockchain de LTCOIN previene fraudes, ofreciendo una plataforma segura para tus operaciones.'
    },
    {
        icon:FiCamera,
        image: services_social,
        title:'Expansión de Clientes',
        desc:'Crecimiento de tu red: Aumenta tu base de clientes intercambiando con otros usuarios, ampliando tu mercado potencial.'
    },
    {
        icon:FiBell,
        image: services_value,
        title:'Intercambio de Valor',
        desc:'Amplía tu mercado: Intercambia inmuebles, vehículos, y más, utilizando Luxcoin, en una plataforma que promueve la economía circular.'
    },
    {
        icon:FiMonitor,
        image: services_transaction,
        title:'Flexibilidad de Transacciones',
        desc:'Sin límites de intercambio: Compra y vende sin necesidad de dinero físico, a través de Luxcoin, facilitando operaciones B2B y B2C.'
    },
]

export const clientData = [
    {
        image:client1,
        name:'Calvin Carlo',
        title:'Manager',
        desc:'" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "'
    },
    {
        image:client2,
        name:'Christa Smith',
        title:'Manager',
        desc:'" The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
    {
        image:client3,
        name:'Jemina CLone',
        title:'Manager',
        desc:'" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others"'
    },
    {
        image:client4,
        name:'Smith Vodka',
        title:'Manager',
        desc:'" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts "'
    },
    {
        image:client5,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" There is now an abundance of readable dummy texts. These are usually used when a text is required"'
    },
    {
        image:client6,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero "'
    },
]

export const blogData = [
    {
        image:blog1,
        title:'Design your apps in your own way',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'13th March 2023'
    },
    {
        image:blog2,
        title:'How apps is changing the IT world',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'5th May 2023'
    },
    {
        image:blog3,
        title:'Smartest Applications for Business',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'19th June 2023'
    },
]

export const companyLogo = [company1, company2, company3, company4, company5, company6]