import React, { useState } from "react";
import contact from '../assets/images/contact.svg'

export default function GetInTouch() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [subject, setSubject] = useState('')
    const [emailSend, setEmailSend] = useState(false)

    const endpoint = 'https://6u7whsxrumy6xsdhvwpwp6gcjy0wjnvk.lambda-url.eu-west-1.on.aws/';
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = { name, email, message, subject }
        const fetchPromise = fetch(endpoint, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(data)
        });
        fetchPromise
            .then(response => response.json())
            .then(data => {
                console.log(data); // handle response, catch errors
                setEmailSend(true);
            })
    };

    return (
        <section className="relative lg:py-24 py-16 bg-secondary-background" id="contact">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-primary-foreground text-sm font-semibold uppercase mb-10">Contacta con nosotros</h6>

                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 gap-6 mt-8">
                    <div className="lg:col-span-7 md:col-span-6 shadow p-5 bg-white">
                        <div>
                            <p className="text-accent text-lg font-semibold mx-auto">
                                Estamos Buscando Visionarios
                            </p>
                            <p className="text-slate-400 mx-auto mt-4">
                                Si compartes nuestra pasión por transformar el mercado y construir un futuro con más descentralizado, te invitamos a explorar una oportunidad única de inversión. Nos encantaría dialogar contigo sobre cómo podemos crecer juntos y generar un impacto duradero.
                            </p>
                            <p className="text-accent font-semibold mx-auto mt-4">
                                ¿Listo para Explorar el Futuro Juntos?
                            </p>
                            <p className="text-slate-400 mx-auto mt-4">
                                Si estás interesado en formar parte de este emocionante viaje como inversor en Luxtyche, déjanos tu sdatos y nos ponemos en contacto.
                            </p>
                        </div>
                        {/* <img src={contact} className="h-[250px]" alt="" /> */}
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className={`${!emailSend && 'hidden'} bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6 border border-primary-foreground`}>
                                Gracias por tu mensaje, te responderemos lo antes posible.
                            </div>
                            <div className={`${emailSend && 'hidden'} bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6 border border-primary-foreground`}>
                                <form action={endpoint} onSubmit={handleSubmit} method="POST">
                                    <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div className="lg:col-span-6">
                                            <label htmlFor="name" className="font-semibold">Nombre:</label>
                                            <input
                                                name="name" id="name" type="text"
                                                value={name} onChange={(e) => setName(e.target.value)}
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-primary-foreground dark:border-gray-800 focus:ring-0"
                                            />
                                        </div>

                                        <div className="lg:col-span-6">
                                            <label htmlFor="email" className="font-semibold">Email:</label>
                                            <input name="email" id="email" type="email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-primary-foreground dark:border-gray-800 focus:ring-0"
                                            />
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="subject" className="font-semibold">Asunto:</label>
                                            <input name="subject" id="subject"
                                                value={subject} onChange={(e) => setSubject(e.target.value)}
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-primary-foreground dark:border-gray-800 focus:ring-0"
                                            />
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="comments" className="font-semibold">Mensaje:</label>
                                            <textarea name="comments" id="comments"
                                                value={message} onChange={(e) => setMessage(e.target.value)}
                                                className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-primary-foreground dark:border-gray-800 focus:ring-0"
                                            >

                                            </textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-primary-background text-white mt-2">Enviar mensaje</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}