import React from "react";
import { Link } from "react-router-dom";
import { servicesData } from "../data/data";

export default function Services() {
    return (
        <section className="relative md:py-24 py-16 bg-white" id="services">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-primary-foreground text-sm font-semibold uppercase mb-10">Conoce las ventajas de LUXTYCHE</h6>

                    <p className="text-slate-400 max-w-xl mx-auto">
                        Concoce las ventajas que <span className="font-semibold text-accent">Luxtyche</span> pone a tu disposición a través de la <span className="font-semibold text-accent">Tokenización</span> y la tecnología <span className="font-semibold text-accent">Blockchain</span>.
                    </p>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
                    {servicesData.map((item, index) => {

                        return (
                            <div className="border border-accent group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit" key={index}>
                                {item.image && <div className="rounded rounded-full"><img src={item.image} alt="" /></div>}

                                <div className="content mt-6 relative z-1">
                                    <Link to="" className="title text-lg font-semibold text-accent">{item.title}</Link>
                                    <p className="text-slate-400 mt-3">{item.desc}</p>

                                    {/* <div className="mt-6">
                                        <Link to="" className="text-teal-500">Read More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}