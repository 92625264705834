import React from "react";

import adv from '../assets/images/team/adv.jpeg'
import generic from '../assets/images/team/generic.png'
import ivan from '../assets/images/team/ivan.jpeg'
import bangles from '../assets/images/team/bar.jpg'

export default function Team() {
    const teamMembers = [
        { name: 'Alberto Del Valle', role: 'CEO y Founder', summary: 'Posee una amplia experiencia como empresario, emprendedor y desarrollador de proyectos.', imageUrl: adv },
        { name: 'Bruno Anglés', role: 'CTO y Founder', summary: '+25 años de experiencia en el sector Tech, construyendo soluciones y liderando equipos.', imageUrl: bangles },
        { name: 'Iván Fuentes', role: 'Investigador Blockchain', summary: 'Dilatada experiencia como director en Blockchain.', imageUrl: ivan },
    ];

    return (
        <section className="relative overflow-hidden md:py-20 py-20 bg-secondary-background" id="team">
            <div className="container relative text-center">
                <h6 className="text-primary-foreground text-sm font-semibold uppercase mb-10">Conoce al Equipo que Impulsa Luxtyche</h6>
                <p className="text-slate-400 mb-4">
                    Detrás de <span className="font-semibold text-accent">Luxtyche</span> se encuentra un grupo apasionado de innovadores, tecnólogos, y visionarios, cada uno aportando una riqueza de experiencia y dedicación al éxito de nuestra plataforma.
                </p>
                <p className="text-slate-400 mb-4">
                    Desde nuestros fundadores, que concibieron Luxtyche desde una visión de <span className="font-semibold text-accent">cambio y sostenibilidad</span>, hasta nuestro talentoso equipo técnico que transforma esta visión en realidad, todos compartimos un compromiso común: <span className="font-semibold text-accent">revolucionar la manera en que intercambiamos bienes y servicios</span>.
                </p>
                <p className="text-slate-400 mb-10">
                    En esta fase inicial, nuestro enfoque está en construir no solo una plataforma robusta y segura, sino también una comunidad de usuarios y colaboradores que crean en un futuro más sostenible y equitativo. Conócenos y descubre cómo juntos estamos estableciendo las bases para un nuevo paradigma económico.
                </p>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 justify-center mt-10">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="text-center">
                            <img src={member.imageUrl} alt={member.name} className="mx-auto w-[250px] rounded-full" />
                            <h2 className="font-bold text-lg">{member.name}</h2>
                            <h3 className="text-lg">{member.role}</h3>
                            <p className="italic">{member.summary}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
