import React from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from "react-scroll";

import heroImg from "../assets/images/luxtyche_hero_image.png"

import Navbar from "../components/navbar";
import About from "../components/about";
import Team from "../components/team";
import Services from "../components/services";
import AgencyTab from "../components/agencyTab";
import Cta from "../components/cta";
import Client from "../components/client";
import Pricing from "../components/pricing";
import Blogs from "../components/blog";
import GetInTouch from "../components/getInTuoch";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

export default function Index() {
    return (
        <>
            <Navbar />
            <section className="relative overflow-hidden md:py-48 py-40 bg-secondary-background" id="home">
                <div className="container relative mt-8">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
                        <div>
                            <h1 className="font-semibold lg:leading-normal leading-normal tracking-wide text-4xl lg:text-5xl mb-5 text-accent">
                                Revoluciona la forma en que intercambias: ¡Bienvenido a Luxtyche!
                            </h1>
                            <p className="font-semibold text-accent text-lg max-w-xl">
                                Trabaja cuando y dónde quieras. Monetiza tanto tus objetos, tus habilidades y tus profesiones.
                            </p>
                            <p className="text-slate-400 text-lg max-w-xl">
                                Nuestra plataforma te permite intercambiar servicios y productos sin dinero tradicional.
                                Sumérgete en un mercado vibrante y sostenible que promueve la economía circular a través de la <span className="font-semibold text-accent">tecnología Blockchain.</span>
                            </p>

                            <div className="mt-6">
                                <Link1 to="contact" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-primary-background text-white">Contacta con nosotros <i className="mdi mdi-chevron-right ms-1"></i></Link1>
                            </div>
                        </div>

                        <div className="lg:ms-8">
                            <div className="relative">
                                <img src={heroImg} className="relative rounded-xl" alt="" />
                                {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tl to-teal-500/20 via-teal-500/70 from-teal-500 bottom-1/2 translate-y-1/2 md:start-0 start-1/2 ltr:md:translate-x-0 ltr:-translate-x-1/2 rtl:md:translate-x-0 rtl:translate-x-1/2 -z-1 shadow-md shadow-teal-500/10 rounded-full"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Cta /> */}
            <About />
            <Team />
            <Services />
            {/* <AgencyTab /> */}
            {/* <Client /> */}
            {/* <Pricing /> */}
            {/* <Blogs /> */}
            <GetInTouch />
            <Footer />
            <Switcher />
        </>
    )
}