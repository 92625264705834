import React, { useState } from "react";
import { Link } from "react-router-dom";
import aboutImage from '../assets/images/about.jpg'

import CountUp from 'react-countup';

import ctaImage from "../assets/images/home.png"

import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

export default function About() {
    let [isOpen, setOpen] = useState(false);
    return (
        <section className="relative md:py-24 py-16" id="about">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="md:col-span-6">
                        <div className="lg:me-8">
                            <div className="relative">
                                {/* <img src={aboutImage} className="rounded-full shadow dark:shadow-gray-700" alt="" /> */}

                                <img src={ctaImage} alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#!" onClick={() => setOpen(true)}
                                        className="lightbox size-10 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-primary-background dark:text-white">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div>
                                <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 1, hl: 'es', cc_lang_pref: 'es', cc_load_policy: 1}}
                                    isOpen={isOpen}
                                    videoId="xeGtroLK8ZE"
                                    onClose={() => setOpen(false)}
                                />

                                {/* <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700">
                                    <div className="text-center">
                                        <span className="text-teal-500 text-2xl font-semibold mb-0 block"><CountUp className="counter-value text-6xl font-semibold" start={0} end={15} />+</span>
                                        <span className="font-semibold block mt-2">Years <br /> Experience</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-6">
                        <div className="lg:ms-8">
                            <h6 className="text-primary-foreground text-sm font-semibold uppercase mb-2">Nuestra visión y motivación</h6>
                            <h3 className="font-semibold text-2xl leading-normal mb-4 text-accent">
                                Transformando el Intercambio:<br /> Bienvenidos a la Era de Luxtyche.
                            </h3>

                            <p className="text-slate-400 max-w-xl mb-4">
                                En Luxtyche, estamos en la emocionante fase pre seed, poniendo los cimientos para revolucionar el intercambio de bienes y servicios.
                            </p>
                            <p className="text-slate-400 max-w-xl mb-4">
                                Nuestra plataforma digital, impulsada por una innovadora moneda, facilita transacciones sin efectivo,
                                promoviendo una economía circular y el consumo responsable.
                            </p>
                            <p className="text-slate-400 max-w-xl mb-4">
                                Inspirados por una necesidad de cambio en las prácticas de intercambio tradicionales,
                                nos esforzamos por crear un sistema inclusivo que permita a todos, desde individuos hasta pequeñas empresas, aprovechar sus recursos de manera sostenible.
                            </p>
                            <p className="text-slate-400 max-w-xl mb-4">
                                Únete a nosotros en este viaje hacia la transformación económica y social.
                            </p>

                            {/* <Link to="" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-primary-background text-white">Leer Más <i className="mdi mdi-chevron-right align-middle ms-0.5"></i></Link> */}

                            <div className="grid grid-cols-1 md:text-start text-center justify-center">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}